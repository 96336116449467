import styled from "styled-components"

export const Wrapper = styled.section`
  margin: 30px 0;
`

export const Box = styled.div`
  padding: 30px;
  border: 1px solid rgba(218, 210, 210, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Headline = styled.h5`
  text-align: center;
  width: 100%;
  /* display: flex; */
  align-items: center;
  color: #dad2d2;
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: 2;
  text-transform: uppercase;
  margin-bottom: 1em;
`

export const Distance = styled.p`
  color: #f2eeed;
  font-family: "Oswald", sans-serif;
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.1em;
  line-height: normal;
  text-align: center;
`

export const KM = styled.span`
  font-size: 28px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
`

export const BoxImage = styled.div`
  padding: 30px 0;
`

export const P = styled.p`
  color: #dad2d2;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.08;
  line-height: 30px;
  text-align: left;
`
